<template>
  <div
    class="bg-white rounded-lg shadow sm:w-full sm:mx-auto sm:overflow-hidden"
  >
    <div class="sm:container bg-blue-400 px-4 py-8 sm:px-10">
      <div
        class="sm:w-3/4 w-full mx-auto flex flex-col sm:flex sm:flex-row sm:items-center sm:space-x-2"
      >
        <div class="w-full">
          <div class="relative">
            <input
              type="text"
              id="search-form-price"
              @keypress.enter="findPayment"
              v-model="receiptNumber"
              class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
              placeholder="Receipt Number / Payment Ref"
            />
          </div>
        </div>
        <button
          type="button"
          @click.prevent="findPayment"
          :disabled="findingPayment"
          class="px-8 py-2 flex items-center justify-center mt-4 sm:mt-0 mx-auto w-full sm:w-auto text-center font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-primary focus:outline-none"
        >
          <div v-if="!findingPayment">Search</div>
          <div v-else class="flex items-center space-x-2 py-1 justify-center">
            <i class="fas fa-circle-notch animate-spin text-sm"></i>
            <span class="text-sm">loading...</span>
          </div>
        </button>
      </div>
    </div>
    <div class="w-full sm:w-3/4 sm:px-7 py-10 mx-auto">
      <div
        class="w-full flex flex-row items-start justify-between text-left mb-10 px-2"
      >
        <div>
          <h1
            class="sm:text-3xl text-2xl font-medium text-left title-font text-gray-700 mb-4"
          >
            Receipt Details
          </h1>
          <p class="text-base leading-relaxed text-gray-500">
            Enter the new details below and update to make changes.
          </p>
        </div>
      </div>
      <div class="flex flex-wrap px-4 sm:px-0 sm:mx-auto sm:mb-2 -mx-2">
        <div class="p-2 sm:w-1/2 w-full sm:border-b sm:border-gray-50 sm:pb-4">
          <div class="w-full">
            <div class="relative">
              <label for="customer" class="text-gray-500">
                Customer name
              </label>
              <input
                name="customer"
                type="text"
                disabled
                v-model="paymentDetails.customer"
                class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                placeholder="Enter customer name"
              />
            </div>
          </div>
        </div>
        <div
          class="p-2 sm:w-1/2 w-full mb-4 sm:mb-0 border-b border-gray-50 sm:pb-4"
        >
          <div class="w-full">
            <div class="relative">
              <label for="customer" class="text-gray-500">
                New Customer name
              </label>
              <input
                name="new_customer"
                type="text"
                v-model="updatedDetails.customer_name"
                class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                placeholder="Enter new customer name"
              />
            </div>
          </div>
        </div>
        <div class="p-2 sm:w-1/2 w-full sm:border-b sm:border-gray-50 sm:pb-4">
          <div class="w-full">
            <div class="relative">
              <label for="revenue_item" class="text-gray-500">
                Revenue Item
              </label>
              <input
                name="revenue_item"
                type="text"
                disabled
                v-model="paymentDetails.revenue_item"
                class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                placeholder="Enter Revenue Item"
              />
            </div>
          </div>
        </div>
        <div
          class="p-2 sm:w-1/2 w-full mb-4 sm:mb-0 border-b border-gray-50 sm:pb-4"
        >
          <div class="w-full">
            <label for="revenueItems" class="text-gray-500">
              New Revenue item
            </label>
            <select
              id="revenueItems"
              v-model="revenue_item"
              class="block w-full py-2.5 text-gray-600 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
              name="revenueItems"
            >
              <option disabled :value="revenue_item">
                {{ revenue_item || "Select new item" }}
              </option>
              <option
                v-for="item in revenueItems"
                :key="item.revenue_item"
                :value="item.revenue_item"
              >
                {{ item.revenue_item }}
              </option>
            </select>
          </div>
        </div>
        <div class="p-2 sm:w-1/2 w-full">
          <div class="w-full">
            <div class="relative">
              <label for="revenue_category" class="text-gray-500">
                Revenue Category
              </label>
              <input
                name="revenue_category"
                type="text"
                disabled
                v-model="paymentDetails.revenue_category"
                class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                placeholder="Revenue Category"
              />
            </div>
          </div>
        </div>
        <div class="p-2 sm:w-1/2 w-full">
          <div class="w-full">
            <label for="revenueCategories" class="text-gray-500">
              New revenue category
            </label>
            <select
              id="revenueCategories"
              v-model="revenue_category"
              class="block w-full py-2.5 text-gray-600 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
              name="revenueCategories"
            >
              <option disabled :value="revenue_category">
                {{ revenue_category || "Select new category" }}
              </option>
              <option
                v-for="item in revenueCategories"
                :key="item"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        class="sm:flex sm:flex-row px-4 sm:px-2 sm:justify-start sm:space-x-4 sm:container sm:mx-auto w-full"
      >
        <button
          type="button"
          @click.prevent="resetForm"
          class="px-8 py-2 sm:my-10 mt-8 w-full sm:w-1/2 font-semibold text-white text-md transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-red-600 hover:from-red-600 to-red-600 hover:to-red-600 focus:ring focus:ring-red-600 focus:outline-none"
        >
          <div>reset</div>
        </button>
        <button
          type="button"
          @click.prevent="updatePayment"
          :disabled="updatingPayment"
          class="px-8 py-2 sm:my-10 mt-4 mb-20 w-full sm:w-1/2 font-semibold text-white text-md transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-green-600 hover:from-green-600 to-green-600 hover:to-green-600 focus:ring focus:ring-green-600 focus:outline-none"
        >
          <div v-if="!updatingPayment">Update details</div>
          <div v-else class="flex items-center space-x-4 justify-center">
            <i class="fas fa-circle-notch animate-spin"></i>
            <span>Please wait...</span>
          </div>
        </button>
      </div>
    </div>
    <!-- <div class="px-4 py-6 border-t-2 border-gray-200 bg-gray-50 sm:px-10">
                <p class="text-xs leading-5 text-gray-500">
                    This data are display for information and can change
                </p>
            </div> -->
  </div>
</template>

<script>
// import { mixin as clickaway } from "vue-clickaway";
// import moment from "moment";
// import numeral from "numeral";
// import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import { views } from "@/helpers/login_details.js";
export default {
  //  mixins: [clickaway],

  data: () => ({
    canViewManageReceipts: false,
    findingPayment: false,
    updatingPayment: false,
    receiptNumber: "",
    revenueItems: [],
    revenue_item: "",
    revenue_category: "",
    revenueCategories: [],
    paymentDetails: {},
    updatedDetails: {},
  }),
  watch: {
    revenue_item(val) {
      this.updatedDetails.revenue_item = val;
    },
    revenue_category(val) {
      this.updatedDetails.revenue_category = val;
    },
  },
  computed: {},
  methods: {
    resetForm() {
      this.paymentDetails = {};
      this.updatedDetails = {};
    },
    async findPayment() {
      try {
        if (this.receiptNumber.length > 0) {
          this.findingPayment = true;
          const paymentDetails = await this.$store.dispatch(
            "getPaymentDetails",
            { receipt_number: this.receiptNumber }
          );

          const { status, msg } = paymentDetails;

          if (status == "success") {
            this.revenueItems = await this.$store.dispatch(
              "fetchItemsToFilter",
              "Revenue Item"
            );
            this.revenueCategories = await this.$store.dispatch(
              "fetchItemsToFilter",
              "Revenue Category"
            );

            this.paymentDetails = paymentDetails.data;
            this.updatedDetails.customer_name = this.paymentDetails.customer;
            this.updatedDetails.revenue_item =
              this.paymentDetails.revenue_item == null
                ? ""
                : this.paymentDetails.revenue_item;
            this.updatedDetails.revenue_category =
              this.paymentDetails.revenue_category == null
                ? ""
                : this.paymentDetails.revenue_category;

            if (this.updatedDetails.revenue_item != null) {
              this.revenue_item = this.updatedDetails.revenue_item;
            }
            if (this.updatedDetails.revenue_category != null) {
              this.revenue_category = this.updatedDetails.revenue_category;
            }
          } else {
            Swal.fire({
              title: "Revenue House",
              icon: "error",
              text: msg || "Something went wrong",
            });
          }
        } else {
          Swal.fire({
            title: "Revenue House",
            icon: "error",
            text: "Please enter a receipt number or payment ref.",
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.findingPayment = false;
      }
    },
    async updatePayment() {
      try {
        if (this.updatedDetails.customer_name) {
          if (this.updatedDetails.customer_name.length > 0) {
            this.updatingPayment = true;
            const result = await this.$store.dispatch("updatePaymentDetails", {
              receipt_number: this.receiptNumber,
              ...this.updatedDetails,
            });

            const { status, msg } = result.data;

            if (status == "success") {
              Swal.fire({
                title: "Revenue House",
                icon: "success",
                text:
                  msg ||
                  "Payment details submitted for approval and authorization...",
              });
              this.resetForm();
            } else {
              Swal.fire({
                title: "Revenue House",
                icon: "error",
                text: msg || "Update failed, please try again.",
              });
            }
          } else {
            Swal.fire({
              title: "Revenue House",
              icon: "error",
              text: "Please enter a receipt number and search.",
            });
          }
        } else {
          Swal.fire({
            title: "Revenue House",
            icon: "error",
            text: "Please enter new payment details.",
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.updatingPayment = false;
      }
    },
  },
  created() {
    if (views.includes("ManageReceipts")) {
      this.canViewManageReceipts = true;
    } else {
      this.canViewManageReceipts = false;
    }
  },
};
</script>

<style></style>
