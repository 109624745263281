<template>
  <div>
    <teleport to=".modal">
      <authorize-modal
        title="Receipt Details"
        v-show="showEditReceiptDetailsModal"
        @hideModal="showEditReceiptDetailsModal = false"
      >
        <template #default>
          <div class="w-full">
            <div class="w-full text-left mb-10">
              <p class="text-base leading-relaxed text-gray-500 sm:pl-0">
                Enter the new details below and update to make changes.
              </p>
            </div>
            <div
              class="flex flex-col items-center justify-start px-2 sm:px-0 sm:mx-auto sm:mb-10 -mx-2"
            >
              <div class="w-full sm:border-b sm:border-gray-50 sm:pb-4">
                <div class="w-full">
                  <div class="relative text-left mt-4 sm:mt-0">
                    <label for="customer" class="text-gray-500 text-left">
                      Customer name
                    </label>
                    <input
                      name="customer"
                      type="text"
                      v-model="receiptDetailsForUpdate.customer_name"
                      class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                      placeholder="Enter customer name"
                    />
                  </div>
                </div>
              </div>

              <div class="w-full sm:border-b sm:border-gray-50 sm:pb-4">
                <div class="w-full">
                  <div class="relative text-left mt-4 sm:mt-0">
                    <label for="revenue_item" class="text-gray-500">
                      Revenue Item
                    </label>
                    <input
                      name="revenue_item"
                      type="text"
                      v-model="receiptDetailsForUpdate.revenue_item"
                      class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                      placeholder="Enter Revenue Item"
                    />
                  </div>
                </div>
              </div>

              <div class="w-full">
                <div class="w-full">
                  <div class="relative text-left mt-4 sm:mt-0">
                    <label for="revenue_category" class="text-gray-500">
                      Revenue Category
                    </label>
                    <input
                      name="revenue_category"
                      type="text"
                      v-model="receiptDetailsForUpdate.revenue_category"
                      class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      placeholder="Revenue Category"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template #action>
          <button
            type="button"
            @click.prevent="updateReceipt"
            :disabled="updatingReceiptDetails"
            class="w-full sm:w-auto flex items-center justify-center font-medium mt-3 rounded-md border-2 border-gray-400 hover:bg-gray-500 hover:text-white shadow-sm px-4 py-2 bg-white text-base text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:ml-3 sm:text-sm"
          >
            <div v-if="!updatingReceiptDetails">update</div>
            <div v-else class="flex items-center space-x-4 justify-center">
              <i class="fas fa-circle-notch animate-spin"></i>
              <span>Please wait...</span>
            </div>
          </button>
        </template>
      </authorize-modal>
    </teleport>
    <teleport to=".modal">
      <authorize-modal
        title="Receipt Details"
        v-show="showReceiptDetailsModal"
        @hideModal="showReceiptDetailsModal = false"
      >
        <template #default>
          <div
            class="flex flex-row justify-start items-center mb-4 pb-4 rounded bg-gray-50"
          >
            <div
              v-if="receiptDetails.initiator"
              class="text-center w-1/3 h-14 sm:h-10"
            >
              <span class="text-xs text-center text-gray-500"
                >Initiated By</span
              >
              <div
                class="flex flex-col border-t border-gray-300 text-center mt-0.5 py-1 px-4 text-gray-600"
              >
                <span class="text-xs font-medium">{{
                  abbreviateName(receiptDetails.initiator)
                }}</span>
              </div>
            </div>
            <div
              v-if="receiptDetails.approvedBy"
              class="text-center w-1/3 h-14 sm:h-10"
            >
              <span class="text-xs text-center text-gray-500">Approved By</span>
              <div
                class="flex flex-col border-t border-gray-300 text-center mt-0.5 py-1 px-4 text-gray-600"
              >
                <span class="text-xs font-medium">{{
                  abbreviateName(receiptDetails.approvedBy)
                }}</span>
              </div>
            </div>
            <div
              v-if="receiptDetails.authorizedBy"
              class="text-center w-1/3 h-14 sm:h-10"
            >
              <span class="text-xs text-center text-gray-500">
                Authorized By
              </span>
              <div
                class="flex flex-col border-t border-gray-300 text-center mt-0.5 py-1 px-4 text-gray-600"
              >
                <span class="text-xs font-medium">{{
                  abbreviateName(receiptDetails.authorizedBy)
                }}</span>
              </div>
            </div>
          </div>
          <div class="text-left border-gray-100 pt-4">
            <dl>
              <template
                v-if="
                  receiptDetails.old_customer_name !==
                  receiptDetails.customer_name
                "
              >
                <div
                  class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-xs font-medium text-gray-700">
                    Old Customer Name
                  </dt>
                  <dd
                    class="mt-1 text-sm text-red-700 sm:mt-0 sm:col-span-2 font-medium"
                  >
                    {{ receiptDetails.old_customer_name || "N/A" }}
                  </dd>
                </div>

                <div
                  class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-b border-gray-100 mb-2"
                >
                  <dt class="text-sm font-medium text-gray-700">
                    New Customer name
                  </dt>
                  <dd
                    class="mt-1 text-md text-green-700 sm:mt-0 sm:col-span-2 font-semibold"
                  >
                    {{ receiptDetails.customer_name || "N/A" }}
                  </dd>
                </div>
              </template>

              <template
                v-if="
                  receiptDetails.old_revenue_item !==
                  receiptDetails.revenue_item
                "
              >
                <div
                  class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-xs font-medium text-gray-700">
                    Old Revenue Item
                  </dt>
                  <dd
                    class="mt-1 text-sm text-red-700 sm:mt-0 sm:col-span-2 font-semibold"
                  >
                    {{ receiptDetails.old_revenue_item || "N/A" }}
                  </dd>
                </div>
                <div
                  class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-sm font-medium text-gray-700">
                    New Revenue Item
                  </dt>
                  <dd
                    class="mt-1 text-md text-green-700 sm:mt-0 sm:col-span-2 font-semibold"
                  >
                    {{ receiptDetails.revenue_item || "N/A" }}
                  </dd>
                </div>
              </template>
              <template
                v-if="
                  receiptDetails.old_revenue_category !==
                  receiptDetails.revenue_category
                "
              >
                <div
                  class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t border-gray-100 mt-2"
                >
                  <dt class="text-xs font-medium text-gray-700">
                    Old Revenue Category
                  </dt>
                  <dd
                    class="mt-1 text-sm text-red-700 sm:mt-0 sm:col-span-2 font-semibold"
                  >
                    {{ receiptDetails.old_revenue_category || "N/A" }}
                  </dd>
                </div>
                <div
                  class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-sm font-medium text-gray-700">
                    New Revenue Category
                  </dt>
                  <dd
                    class="mt-1 text-md text-green-700 sm:mt-0 sm:col-span-2 font-semibold"
                  >
                    {{ receiptDetails.revenue_category || "N/A" }}
                  </dd>
                </div>
              </template>
            </dl>
          </div>
        </template>

        <template
          v-if="
            status == 'pending' && role == 'director' && unit == 'collections'
          "
          #action
        >
          <button
            type="button"
            @click.prevent="authorizeReceiptUpdate('approve')"
            :disabled="authorizingReceiptUpdate"
            class="w-full sm:w-auto flex items-center justify-center font-medium mt-3 rounded-md border-2 border-gray-400 hover:bg-gray-500 hover:text-white shadow-sm px-4 py-2 bg-white text-base text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:ml-3 sm:text-sm"
          >
            <div v-if="!authorizingReceiptUpdate">Approve</div>
            <div v-else class="flex items-center space-x-4 justify-center">
              <i class="fas fa-circle-notch animate-spin"></i>
              <span>Please wait...</span>
            </div>
          </button>
        </template>
        <template
          v-else-if="
            status == 'approved' && role == 'director' && unit == 'chairman'
          "
          #action
        >
          <button
            type="button"
            @click.prevent="authorizeReceiptUpdate('authorize')"
            :disabled="authorizingReceiptUpdate"
            class="w-full sm:w-auto flex items-center justify-center font-medium mt-3 rounded-md border-2 border-gray-400 hover:bg-gray-500 hover:text-white shadow-sm px-4 py-2 bg-white text-base text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:ml-3 sm:text-sm"
          >
            <div v-if="!authorizingReceiptUpdate">Authorize</div>
            <div v-else class="flex items-center space-x-4 justify-center">
              <i class="fas fa-circle-notch animate-spin"></i>
              <span>Please wait...</span>
            </div>
          </button>
        </template>
      </authorize-modal>
    </teleport>
    <div class="w-full sm:px-0">
      <div class="sm:py-8">
        <div class="hidden bg-blue-400 px-4 py-8 sm:px-10">
          <div
            class="w-full mx-auto flex flex-col sm:flex sm:flex-row sm:items-center sm:space-x-2"
          >
            <div class="w-full">
              <div class="relative">
                <input
                  type="text"
                  id="search-form-price"
                  @keypress.enter="findReceipts"
                  v-model="receiptNumber"
                  class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                  placeholder="Receipt number"
                />
              </div>
            </div>
            <button
              type="button"
              @click.prevent="findReceipts"
              :disabled="findingReceipts"
              class="px-8 py-2 flex items-center justify-center mt-4 sm:mt-0 mx-auto w-full sm:w-auto text-center font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-primary focus:outline-none"
            >
              <div v-if="!findingReceipts">Search</div>
              <div
                v-else
                class="flex items-center space-x-2 py-1 justify-center"
              >
                <i class="fas fa-circle-notch animate-spin text-sm"></i>
                <span class="text-sm">loading...</span>
              </div>
            </button>
          </div>
        </div>

        <div
          class="flex sm:flex-row sm:items-start flex-col-reverse sm:space-x-4"
        >
          <button
            v-if="
              status == 'pending' &&
              role == 'director' &&
              unit == 'collections' &&
              mainReceipts.length > 0
            "
            type="button"
            @click.prevent="authorizeAllReceiptUpdates('approve')"
            :disabled="authorizingAllReceiptUpdates"
            class="px-8 py-0 h-10 text-md flex items-center justify-center my-3 sm:my-0 w-full sm:w-3/12 text-center font-semibold text-white transition duration-500 ease-in-out transform rounded-md shadow-sm bg-green-600 focus:outline-none"
          >
            <div v-if="!authorizingAllReceiptUpdates">Approve all</div>
            <div v-else class="flex items-center space-x-2 0 justify-center">
              <i class="fas fa-circle-notch animate-spin text-sm"></i>
              <span class="text-sm">loading...</span>
            </div>
          </button>
          <button
            v-else-if="
              status == 'approved' &&
              role == 'director' &&
              unit == 'chairman' &&
              mainReceipts.length > 0
            "
            type="button"
            @click.prevent="authorizeAllReceiptUpdates('authorize')"
            :disabled="authorizingAllReceiptUpdates"
            class="px-8 py-0 h-10 text-md flex items-center justify-center my-3 sm:my-0 w-full sm:w-3/12 text-center font-semibold text-white transition duration-500 ease-in-out transform rounded-md shadow-sm bg-green-600 focus:outline-none"
          >
            <div v-if="!authorizingAllReceiptUpdates">Authorize all</div>
            <div v-else class="flex items-center space-x-2 py-1 justify-center">
              <i class="fas fa-circle-notch animate-spin text-sm"></i>
              <span class="text-sm">loading...</span>
            </div>
          </button>
          <select
            v-model="status"
            class="block capitalize sm:w-52 w-full sm:mt-0 sm:mb-4 mt-4 mb-2 text-gray-700 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
            name="status"
          >
            <option
              v-for="status in receiptDetailsStatuses"
              :value="status"
              :key="status"
            >
              {{ status }}
            </option>
          </select>
          <div class="w-full">
            <div class="relative">
              <input
                type="text"
                v-model="filter"
                class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-1.5 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                placeholder=" Customer name, Revenue Item or Category"
              />
            </div>
          </div>
        </div>
        <div
          class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto mt-4 sm:mt-0 bg-gray-50 sm:bg-white"
        >
          <p v-if="mainReceipts.length > 0" class="text-xs text-gray-500">
            Click on a row to view details.
          </p>
          <div class="inline-block w-full shadow rounded-lg overflow-x-auto">
            <table class="w-full leading-normal">
              <thead>
                <tr class="sticky top-0 bg-blue-400">
                  <th
                    scope="col"
                    class="px-5 py-3 flex flex-row items-center text-gray-50 text-left text-xs sm:text-sm uppercase font-semibold"
                  >
                    Customer name
                    <div
                      v-if="mainReceipts.length > 0"
                      class="ml-2 cursor-pointer"
                    >
                      <i
                        v-if="sortByName === 'asc'"
                        @click="sortReceiptsByName('desc')"
                        class="fas fa-arrow-down text-xs"
                      ></i>
                      <i
                        v-else
                        @click="sortReceiptsByName('asc')"
                        class="fas fa-arrow-up text-xs"
                      ></i>
                    </div>
                  </th>
                  <th
                    scope="col"
                    class="px-5 py-3 text-gray-50 text-left text-xs sm:text-sm uppercase font-semibold"
                  >
                    Revenue Item
                  </th>
                  <th
                    scope="col"
                    class="px-5 py-3 text-gray-50 text-left text-xs sm:text-sm uppercase font-semibold"
                  >
                    Revenue Category
                  </th>
                  <th
                    scope="col"
                    class="px-5 py-3 text-gray-50 text-left text-xs sm:text-sm uppercase font-semibold"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="findingReceipts">
                  <td
                    class="px-5 py-2.5 border-b border-dashed border-gray-200 text-sm text-gray-500"
                  >
                    Loading data...
                  </td>
                </tr>
                <tr
                  v-else
                  @click="showReceiptDetails(item)"
                  v-for="item in mainReceipts"
                  :key="item.tracking_id"
                  class="cursor-pointer bg-white hover:bg-gray-100 hover:font-semibold"
                >
                  <td
                    class="px-5 py-2.5 border-b border-dashed border-gray-200 text-sm"
                  >
                    <div
                      :class="[
                        truncateName(item.customer_name) ? 'w-80' : 'w-auto',
                      ]"
                      class="text-gray-700 whitespace-no-wrap"
                    >
                      {{ item.customer_name || "N/A" }}
                    </div>
                  </td>
                  <td
                    class="px-5 py-2.5 border-b border-dashed border-gray-200 text-sm"
                  >
                    <div
                      :class="[
                        truncateName(item.revenue_item) ? 'w-80' : 'w-auto',
                      ]"
                      class="text-gray-700 whitespace-no-wrap"
                    >
                      {{ item.revenue_item || "N/A" }}
                    </div>
                  </td>

                  <td
                    class="px-5 py-2.5 border-b border-dashed border-gray-200 text-sm"
                  >
                    <div
                      :class="[
                        truncateName(item.revenue_category)
                          ? ' w-72'
                          : 'w-auto',
                      ]"
                      class="text-gray-700 whitespace-no-wrap"
                    >
                      {{ item.revenue_category || "N/A" }}
                    </div>
                  </td>
                  <td
                    class="px-5 border-b border-dashed border-gray-200 text-sm"
                  >
                    <div
                      v-if="unit == 'administrator' && status === 'pending'"
                      class="flex flex-row items-center space-x-6"
                    >
                      <button
                        @click.stop="setReceiptDetailsForUpdate(item)"
                        class="text-blue-500 hover:text-blue-700 cursor-pointer"
                      >
                        <i class="fas fa-edit text-xl"></i>
                      </button>
                      <button
                        @click.stop="deleteUpdateRequest(item)"
                        class="text-red-600 hover:text-red-900 cursor-pointer"
                      >
                        <i class="fas fa-trash text-xl"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="hidden px-5 bg-white py-5 flex-col xs:flex-row items-center xs:justify-between"
            >
              <div class="flex items-center">
                <button
                  type="button"
                  class="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100"
                >
                  <svg
                    width="9"
                    fill="currentColor"
                    height="8"
                    class=""
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z"
                    ></path>
                  </svg>
                </button>
                <button
                  type="button"
                  class="w-full px-4 py-2 border-t border-b text-base text-indigo-500 bg-white hover:bg-gray-100"
                >
                  1
                </button>
                <button
                  type="button"
                  class="w-full px-4 py-2 border text-base text-gray-600 bg-white hover:bg-gray-100"
                >
                  2
                </button>
                <button
                  type="button"
                  class="w-full px-4 py-2 border-t border-b text-base text-gray-600 bg-white hover:bg-gray-100"
                >
                  3
                </button>
                <button
                  type="button"
                  class="w-full px-4 py-2 border text-base text-gray-600 bg-white hover:bg-gray-100"
                >
                  4
                </button>
                <button
                  type="button"
                  class="w-full p-4 border-t border-b border-r text-base rounded-r-xl text-gray-600 bg-white hover:bg-gray-100"
                >
                  <svg
                    width="9"
                    fill="currentColor"
                    height="8"
                    class=""
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthorizeModal from "@/components/AuthorizeModal.vue";
import Swal from "sweetalert2";
export default {
  props: ["role", "unit"],
  components: {
    AuthorizeModal,
  },
  data: () => ({
    receipts: [],
    receiptDetailsStatuses: ["pending", "approved", "authorized"],
    status: "pending",
    filter: "",
    receiptNumber: "",
    findingReceipts: false,
    showReceiptDetailsModal: false,
    showEditReceiptDetailsModal: false,
    authorizingReceiptUpdate: false,
    authorizingAllReceiptUpdates: false,
    updatingReceiptDetails: false,
    receiptDetails: {},
    trackingIds: [],
    receiptDetailsForUpdate: {},
    sortByName: "asc",
  }),
  computed: {
    mainReceipts() {
      //   return this.receipts;
      return this.receipts.filter((receipt) => {
        return (
          receipt.customer_name
            .toLowerCase()
            .includes(this.filter.toLowerCase()) ||
          receipt.revenue_item
            .toLowerCase()
            .includes(this.filter.toLowerCase()) ||
          receipt.revenue_category
            .toLowerCase()
            .includes(this.filter.toLowerCase())
        );
      });
    },
  },
  watch: {
    async status() {
      try {
        this.fetchReceipts();
      } catch (error) {
        console.error(error);
      }
    },
    sortByName(val) {
      let name1, name2;
      this.receipts = this.receipts.sort((a, b) => {
        if (val !== "asc") {
          name1 = a.customer_name.toUpperCase();
          name2 = b.customer_name.toUpperCase();
        } else {
          name1 = b.customer_name.toUpperCase();
          name2 = a.customer_name.toUpperCase();
        }

        let comparison = 0;

        if (name1 > name2) {
          comparison = 1;
        } else if (name1 < name2) {
          comparison = -1;
        }
        return comparison;
      });
    },
  },
  methods: {
    async updateReceipt() {
      try {
        let result = await this.$store.dispatch("editPaymentReceipt", {
          ...this.receiptDetailsForUpdate,
        });
        const { status, msg } = result;

        if (status === "success") {
          await Swal.fire("Deleted!", msg, "success");
          await this.fetchReceipts();
        } else {
          Swal.fire("Delete failed!", msg, "error");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.showEditReceiptDetailsModal = false;
      }
    },
    setReceiptDetailsForUpdate(details) {
      this.receiptDetailsForUpdate.customer_name = details.customer_name;
      this.receiptDetailsForUpdate.revenue_category = details.revenue_category;
      this.receiptDetailsForUpdate.revenue_item = details.revenue_item;
      this.receiptDetailsForUpdate.tracking_id = details.tracking_id;
      this.showEditReceiptDetailsModal = true;
    },
    async deleteUpdateRequest(request) {
      try {
        Swal.fire({
          title: "Are you sure?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            return this.$store
              .dispatch("deletePaymentReceipt", {
                tracking_id: request.tracking_id,
              })
              .then(async (result) => {
                const { status, msg } = result;

                if (status === "success") {
                  await Swal.fire("Deleted!", msg, "success");
                  await this.fetchReceipts();
                } else {
                  Swal.fire("Delete failed!", msg, "error");
                }
              })
              .catch((error) => {
                Swal.showValidationMessage(`Request failed: ${error}`);
              });
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            return;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    abbreviateName(name) {
      if (name !== "") {
        if (name.split(" ").length > 1) {
          let first =
            name.split(" ")[0].length > 4
              ? name.split(" ")[0].substring(0, 1) + "."
              : name.split(" ")[0];
          let last =
            name.split(" ").length > 2
              ? name.split(" ")[2]
              : name.split(" ")[1];
          return `${first} ${last}`;
        } else {
          return name;
        }
      } else {
        return "";
      }
    },
    truncateName(name) {
      if (name != null) {
        if (name.length > 10) return true;
      }

      return false;
    },
    sortReceiptsByName(direction) {
      this.sortByName = direction;
    },
    async authorizeAllReceiptUpdates(receiptStatus) {
      try {
        this.authorizingAllReceiptUpdates = true;
        this.trackingIds = [];
        this.receipts.forEach((r) => {
          this.trackingIds.push(r.tracking_id);
        });
        let results = await this.$store.dispatch(
          "authorizePaymentDetailsUpdate",
          { tracking_ids: this.trackingIds, status: receiptStatus }
        );

        const { status, message, msg } = results.data;

        if (status === "success") {
          Swal.fire({
            title: "Revenue House",
            icon: "success",
            text: msg || message,
          });
          await this.fetchReceipts();
        } else {
          Swal.fire({
            title: "Revenue House",
            icon: "error",
            text: msg || message,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.authorizingAllReceiptUpdates = false;
      }
    },
    async authorizeReceiptUpdate(receiptStatus) {
      try {
        this.authorizingReceiptUpdate = true;
        let results = await this.$store.dispatch(
          "authorizePaymentDetailsUpdate",
          {
            tracking_ids: [this.receiptDetails.tracking_id],
            status: receiptStatus,
          }
        );

        const { status, message, msg } = results.data;

        if (status === "success") {
          Swal.fire({
            title: "Revenue House",
            icon: "success",
            text: msg || message,
          });
          this.showReceiptDetailsModal = false;
          await this.fetchReceipts();
        } else {
          Swal.fire({
            title: "Revenue House",
            icon: "error",
            text: msg || message,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.authorizingReceiptUpdate = false;
      }
    },
    findReceipts() {},
    showReceiptDetails(details) {
      this.receiptDetails = details;
      this.showReceiptDetailsModal = true;
    },
    async fetchReceipts(path) {
      try {
        this.findingReceipts = true;

        let results = await this.$store.dispatch(path || "getPaymentReceipts", {
          status: this.status,
        });
        this.receipts = results.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.findingReceipts = false;
      }
    },
  },
  async mounted() {
    try {
      if (this.role === "director") this.status = "pending";
      if (this.unit === "chairman") {
        this.receiptDetailsStatuses.splice(0, 1);
        this.status = "approved";
      }
      await this.fetchReceipts("getPaymentReceipts");
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style></style>
