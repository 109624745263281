<template>
  <div v-if="canViewDetails">
    <section class="text-gray-600 body-font overflow-hidden">
      <div v-if="canViewAllTabs" class="w-full mb-6 lg:mb-0">
        <div class="flex mb-4 text-gray-500">
          <button
            v-for="(value, key) in tabViewOptions"
            :key="key"
            @click="switchTabTo(key)"
            class="capitalize"
            :class="`btnTab ${
              tab == key ? 'border-blue-primary-dark' : 'border-gray-300'
            } `"
          >
            {{ key }}
          </button>
        </div>
      </div>
      <!-- <transition class="bg-gray-0" name="slide-fade"> -->
      <component :is="tabView" :role="role" :unit="unit" class="p-4" />
      <!-- </transition> -->
    </section>
  </div>
  <content-holder v-else :title="'Manage Receipts'" class="p-4">
    <unauthorized-access></unauthorized-access>
  </content-holder>
</template>

<!-- eslint-disable no-unused-vars -->
<script>
import ViewReceipts from "./ViewReceipts.vue";
import UpdateReceipts from "./UpdateReceipts.vue";
export default {
  components: {
    UpdateReceipts,
    ViewReceipts,
  },
};
</script>
<script setup>
import { unit, role, mda } from "@/helpers/login_details.js";
import { ref, onBeforeMount } from "vue";
import ContentHolder from "@/components/ContentHolder.vue";
import UnauthorizedAccess from "@/components/shared/UnauthorizedAccess.vue";

const canViewDetails = ref(false);
const tab = ref("update");
const tabView = ref("UpdateReceipts");
const canViewAllTabs = ref(true);
const tabViewOptions = {
  update: "UpdateReceipts",
  view: "ViewReceipts",
};

function switchTabTo(tabName) {
  tab.value = tabName;
  tabView.value = tabViewOptions[tabName];
}

onBeforeMount(() => {
  if (role == "director") {
    switchTabTo("view");
    canViewAllTabs.value = false;
  }
});
onBeforeMount(() => {
  if (mda == 1)
    if (
      unit.includes("chairman") ||
      unit.includes("administrator") ||
      unit.includes("collections")
    ) {
      canViewDetails.value = true;
    } else {
      canViewDetails.value = false;
    }
});
</script>

<style></style>
